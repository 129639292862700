<template>
  <div>
    <b-card
      title="Input Groups with Buttons"
      no-body
      class="mb-0 pt-2 pl-2 pr-2"
    >
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <b-row>
            <!-- <b-col
              cols="12"
              md="3"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="Fecha Venta"
                rules="required"
              >
                <b-form-group
                  label="Fecha Venta"
                  label-for="fechaVenta"
                >
                  <b-form-datepicker
                    v-model="formData.fechaVenta"
                    disabled="disabled"
                    locale="en"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                </b-form-group>
              </validation-provider>
            </b-col> -->
            <b-col
              cols="12"
              md="4"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="Vendedor"
                rules="required"
              >
                <b-form-group
                  label="Vendedor"
                  label-for="vendedor"
                >
                  <v-select
                    v-model="formData.vendedor"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="nombreCompleto"
                    :options="selectVendedor"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="3"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="Tipo de Venta"
                rules="required"
              >
                <b-form-group
                  label="Tipo de Venta"
                  label-for="tipoVenta"
                >
                  <v-select
                    v-model="formData.tipoVenta"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="descripcion"
                    :options="selectTipoVenta"
                    :state="getValidationState(validationContext)"
                    @input="getFechaVencimiento()"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="3"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="Forma de Pago"
                rules="required"
              >
                <b-form-group
                  label="Forma de Pago"
                  label-for="formaPago"
                >
                  <v-select
                    v-model="formData.formaPago"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="descripcion"
                    :options="selectFormaPago"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="Tipo de Documento"
                rules="required"
              >
                <b-form-group
                  label="Tipo de Documento"
                  label-for="tipoDocumento"
                >
                  <v-select
                    v-model="formData.tipoDocumento"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="descripcion"
                    :options="selectTipoDocumento"
                    :state="getValidationState(validationContext)"
                    @input="getSeries()"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="3"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="RUC / DNI"
                rules="required"
              >
                <b-form-group
                  label="RUC / DNI"
                  label-for="documentoCliente"
                >
                  <b-input-group>
                    <b-form-input
                      ref="documentoCliente"
                      v-model="formData.documentoCliente"
                      type="number"
                      :state="getValidationState(validationContext)"
                    />
                    <b-input-group-append>
                      <b-button
                        size="sm"
                        variant="outline-info"
                        @click.prevent="getSearchDocument('R')"
                      >
                        RUC
                      </b-button>
                      <b-button
                        size="sm"
                        variant="outline-info"
                        @click.prevent="getSearchDocument('D')"
                      >
                        DNI
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="Serie"
                rules="required"
              >
                <b-form-group
                  label="Serie"
                  label-for="serie"
                >
                  <v-select
                    v-model="formData.serie"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="serie"
                    :options="selectSerie"
                    :state="getValidationState(validationContext)"
                    @input="getNroComprobante()"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="Nro Comprobante"
                rules="required"
              >
                <b-form-group
                  label="Nro Comprobante"
                  label-for="nro_comprobante"
                >
                  <b-form-input
                    v-model="formData.nro_comprobante"
                    :state="getValidationState(validationContext)"
                    readonly
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="1"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="Forma de Impresion"
                rules="required"
              >
                <b-form-group
                  label="Forma de Impresion"
                  label-for="forma_print"
                >
                  <v-select
                    v-model="formData.formaPrint"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="descripcion"
                    :options="selectFormaPrint"
                    :state="getValidationState(validationContext)"
                    @input="getNroComprobante()"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="Tipo de Pago"
                rules="required"
              >
                <b-form-group
                  label="Tipo de Pago"
                  label-for="tipo_pago"
                >
                  <v-select
                    v-model="formData.tipoPago"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="descripcion"
                    :options="selectTipoPago"
                    :state="getValidationState(validationContext)"
                    @input="changeTipoPago"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="1"
              class="mb-1 mb-md-0"
            >
              <b-form-group
                label="Saldo Fise"
                label-for="saldoFise"
              >
                <b-form-input
                  v-model="formData.saldoFise"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="1"
              class="mb-1 mb-md-0"
            >
              <b-form-group
                label="Importe Fise"
                label-for="cantidadFise"
              >
                <b-form-input
                  v-model="formData.cantidadFise"
                  :disabled="isFise"
                  :max="formData.saldoFise"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="Razon Social"
                rules="required"
              >
                <b-form-group
                  label="Razon Social"
                  label-for="razon_social"
                >
                  <b-form-input
                    v-model="formData.razon_social"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="3"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="Direccion"
                rules="required"
              >
                <b-form-group
                  label="Direccion"
                  label-for="direccion"
                >
                  <b-form-input
                    v-model="formData.direccion"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="email"
              >
                <b-form-group
                  label="Email"
                  label-for="Email"
                >
                  <b-form-input
                    v-model="formData.email"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="3"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="email"
              >
                <b-form-group
                  label="Cliente Final"
                  label-for="clienteFinal"
                >
                  <v-select
                    v-model="formData.clienteFinal"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="nombreComercial"
                    :options="selectClienteFinal"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="5"
              class="mb-1 mb-md-0"
            >
              <b-form-group
                label="Buscar Producto"
                label-for="producto"
              >
                <v-select
                  v-model="formData.preProducto"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="productoNombre"
                  :options="selectProducto"
                  @input="changeProducto"
                />
              </b-form-group>
            </b-col>
            <!-- <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="U. Medida"
                rules="required"
              >
                <b-form-group
                  label="U. Medida"
                  label-for="U. Medida"
                >
                  <v-select
                    v-model="formData.unidadMedida"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="descripcion"
                    :options="selectFormaPrint"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
              </validation-provider>
            </b-col> -->
            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
            >
              <b-form-group
                label="Precio"
                label-for="precio"
              >
                <b-form-input
                  v-model="formData.prePrecio"
                  type="number"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
            >
              <b-input-group
                style="padding-top: 17px;"
                prepend="Cantidad"
              >
                <b-form-input
                  v-model="formData.preCantidad"
                  type="number"
                  @v-on:keydown.enter="addDetalle"
                />
                <b-input-group-append>
                  <b-button
                    variant="success"
                    class="btn-icon"
                    size="sm"
                    :disabled="isCalcular"
                    @click="calcularCantidadGranel"
                  >
                    <feather-icon icon="PlusSquareIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
            >
              <b-form-group
                label="Unidad M."
                label-for="producto"
              >
                <v-select
                  v-model="formData.preUnidadMedida"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="descripcion"
                  :options="selectunidadMedida"
                  @input="changeunidadMedida"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="1"
              class="mb-1 mt-2 mb-md-0"
            >
              <b-col md="6">
                <b-button
                  variant="primary"
                  class="btn-icon"
                  size="sm"
                  @click="addDetalle"
                >
                  <feather-icon icon="PlusSquareIcon" />
                </b-button>
              </b-col>
              <b-col md="6">
                <b-button
                  variant="success"
                  class="btn-icon"
                  size="sm"
                  @click="addDetalleLibre"
                >
                  <feather-icon icon="PlusSquareIcon" />
                </b-button>
              </b-col>
            </b-col>
            <b-col
              cols="12"
              md="7"
              class="mb-1 mb-md-0"
            >
              <b-form-group
                label="Observacion"
                label-for="observacion"
              >
                <b-form-input v-model="formData.observacion" />
              </b-form-group>
            </b-col>
            <b-col
              v-if="isVencimiento"
              cols="12"
              md="3"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="Fecha Vencimiento"
                rules="required"
              >
                <b-form-group
                  label="Fecha Vencimiento"
                  label-for="fechaVencimiento"
                >

                  <b-form-datepicker
                    v-model="formData.fechaVencimiento"
                    :date-format-options="{day: 'numeric', month: 'numeric', year: 'numeric'}"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
              class="mb-1 mt-2 mb-md-0"
            >
              <b-table
                ref="refUserListTable"
                class="position-relative"
                :items="items"
                responsive
                :fields="fieldsDetalle"
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                empty-text="No se encontraron registros"
                :busy="isBusy"
              >
                <!-- Column: precio -->
                <template #cell(productoNombre)="data">
                  <b-form-input
                    v-if="!data.item.isService"
                    v-model="data.item.productoNombre"
                    disabled="true"
                    size="sm"
                  />
                  <b-form-input
                    v-if="data.item.isService"
                    v-model="data.item.productoNombre"
                    size="sm"
                  />
                </template>
                <template #cell(inputUnidadMedida)="data">
                  <v-select
                    v-model="data.item.unidadMedida"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="descripcion"
                    :options="[data.item.unidadMedida]"
                    disabled
                    class="select-size-sm"
                  />
                </template>
                <!-- Column: precio -->
                <template #cell(inputPrecio)="data">
                  <b-form-input
                    v-model="data.item.precio"
                    size="sm"
                    type="number"
                    step="any"
                    @input="changeCantidadPrecio(data.item,'P')"
                  />
                </template>
                <!-- Column: Cantidad -->
                <template #cell(inputCantidad)="data">
                  <b-form-input
                    v-model="data.item.cantidad"
                    type="number"
                    size="sm"
                    step="any"
                    @input="changeCantidadPrecio(data.item,'C')"
                  />
                </template>
                <!-- Column: Subtotal -->
                <template #cell(inputSubtotal)="data">
                  <b-form-input
                    v-model="data.item.subtotal"
                    readonly
                    size="sm"
                  />
                </template>
                <!-- Column: Actions -->
                <template #cell(acciones)="data">
                  <b-dropdown
                    variant="link"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                  >

                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>
                    <b-dropdown-item
                      @click="deleteDetalle(data.item)"
                    >
                      <feather-icon icon="XSquareIcon" />
                      <span class="align-middle ml-50">Eliminar</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="6"
            />
            <b-col
              cols="12"
              md="2"
              class="mb-1 mt-1 mb-md-0"
            >
              <b-form-group
                label="SubTotal"
                label-for="subtotal"
              >
                <b-form-input
                  v-model="formData.subTotal"
                  readonly
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mb-1 mt-1 mb-md-0"
            >
              <b-form-group
                label="Igv"
                label-for="igv"
              >
                <b-form-input
                  v-model="formData.igv"
                  readonly
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mb-1 mt-1 mb-md-0"
            >
              <b-form-group
                label="Total"
                label-for="total"
              >
                <b-form-input
                  v-model="formData.total"
                  readonly
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="6"
            />
            <b-col
              cols="12"
              md="2"
              class="mb-md-0"
            />
            <b-col
              cols="12"
              md="2"
              class=" mb-md-0"
            >
              <b-form-group
                label="Efectivo"
                label-for="Efectivo"
              >
                <b-form-input
                  v-model="formData.efectivo"
                  type="number"
                  @input="calcularVuelto"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class=" mb-md-0"
            >
              <b-form-group
                label="Vuelto"
                label-for="Vuelto"
              >
                <b-form-input
                  v-model="formData.vuelto"
                  readonly
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row
            class="text-center"
            align-v="center"
          >
            <b-col
              cols="12"
              md="4"
            />
            <b-col
              cols="12"
              md="4"
            >
              <b-button
                variant="primary"
                class="btn-icon"
                type="submit"
                size="lg"
              >
                Guardar
                <feather-icon icon="SaveIcon" />
              </b-button>
            </b-col>
            <b-col
              cols="12"
              md="4"
            />
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  BCard, VBTooltip, BCol, BRow, BFormGroup, BForm, BFormInput, BInputGroup, BInputGroupAppend, BButton, BTable, BDropdown, BDropdownItem, BFormDatepicker,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'
import { useNotify } from '@/helpers/toast'
// import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {
    BCard,
    // BFormDatepicker,
    BCol,
    BRow,
    BFormGroup,
    BForm,
    vSelect,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BTable,
    BDropdown,
    BDropdownItem,
    BFormDatepicker,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      required,
    }
  },
  setup(props, context) {
    const { notify } = useNotify(context)
    const titleForm = ref('')
    const sortBy = ref(null)
    const isBusy = ref(false)
    const blankData = {
      fechaVenta: null,
      fechaVencimiento: null,
      vendedor: 0,
      tipoVenta: { descripcion: 'CONTADO', estado: true, tipoVentaId: 2 },
      formaPago: { descripcion: 'EFECTIVO', estado: true, formaPagoId: 1 },
      formaPrint: { descripcion: 'A4' },
      tipoDocumento: 0,
      serie: 0,
      igv: 0,
      subTotal: 0,
      total: 0,
      nro_comprobante: '',
      razon_social: 'CLIENTE VARIOS',
      documentoCliente: '00000000',
      direccion: '-',
      efectivo: 0,
      vuelto: 0,
      observacion: '',
      email: '',
      clienteFinal: null,
      tipoPago: { descripcion: 'COMPLETO' },
      cantidadFise: 0,
      saldoFise: 0,
      cliente: null,
    }
    const presentaciones = ref([])
    const selectClienteFinal = ref([])
    const presentacionSel = ref({})
    const isVencimiento = ref(false)
    const isCalcular = ref(true)
    const isFise = ref(true)
    const items = ref([])
    const fieldsDetalle = ref([
      { key: 'idDetalle', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
      { key: 'productoNombre', label: 'Producto', sortable: true, thStyle: { width: '35%' } },
      { key: 'inputUnidadMedida', label: 'Unidad M.', thStyle: { width: '18%' } },
      { key: 'inputPrecio', label: 'Precio', thStyle: { width: '14%' } },
      { key: 'inputCantidad', label: 'Cantidad', thStyle: { width: '14%' } },
      { key: 'inputSubtotal', label: 'Subtotal', thStyle: { width: '14%' } },
      { key: 'acciones', thStyle: { width: '5%' } },
    ])
    const formData = ref(JSON.parse(JSON.stringify(blankData)))
    const resetData = () => {
      formData.value = JSON.parse(JSON.stringify(blankData))
    }
    const selectVendedor = ref([])
    const selectTipoVenta = ref([])
    const selectFormaPago = ref([])
    const selectTipoDocumento = ref([])
    const selectSerie = ref([])
    const selectunidadMedida = ref([])
    const userProfile = ref(localStorage.getItem('userProfile'))
    const userData = ref(JSON.parse(localStorage.getItem('userData')))
    const selectFormaPrint = ref([
      { descripcion: 'A4' },
      { descripcion: 'A5' },
      { descripcion: 'TICKET' },
    ])
    const selectTipoPago = ref([
      { descripcion: 'COMPLETO' },
      { descripcion: 'BONO_FISE' },
    ])
    const selectProducto = ref([])
    const getNroComprobante = async () => {
      await store.dispatch('comercial/GET_NRO_COMPROBANTE', { tipo_documento_id: formData.value.tipoDocumento.tipoDocumentoId, serie_id: formData.value.serie.serieId,
      })
        .then(response => {
          // Vue.swal.close()
          if (response) {
            // console.log(formData.value.nro_comprobante)
            formData.value.nro_comprobante = response.nroDocumento
            // console.log(formData.value.nro_comprobante)
          }
        })
    }
    const getSeries = async data => {
      console.log(data)
      await store.dispatch('comercial/GET_SERIES', { tipo_documento_id: formData.value.tipoDocumento.tipoDocumentoId,
      })
        .then(response => {
          if (response) {
            selectSerie.value = response
          }
          selectSerie.value.forEach((element, index) => {
            if (index === 0) {
              formData.value.serie = element
            }
          })
          getNroComprobante()
        })
    }
    const calculoGranel = data => {
      console.log(data)
    }
    const calcularCantidadGranel = () => {
      Vue.swal({
        title: 'Calcular Cantidades',
        html: '<input type="number" id="cantidadC" class="form-control" placeholder="Cantidad"> <input type="number" id="percioC" class="form-control" placeholder="Precio"> <input type="number" id="convercionC" class="form-control" placeholder="Conversion">',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Carcular',
        cancelButtonText: 'Cerrar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger  ml-1',
        },
        buttonsStyling: false,
        preConfirm: () => {
          console.log(Vue.swal.getPopup())
          const cantidadC = Vue.swal.getPopup().querySelector('#cantidadC')
          const precioC = Vue.swal.getPopup().querySelector('#precioC')
          const conversionC = Vue.swal.getPopup().querySelector('#conversionC')
          console.info('acaa', cantidadC, precioC, conversionC)
          if (cantidadC === '') {
            Vue.swal.showValidationMessage('Tiene que ingresar la cantiad')
          }
          if (precioC === '') {
            Vue.swal.showValidationMessage('Tiene que ingresar la precio')
          }
          if (conversionC === '') {
            Vue.swal.showValidationMessage('Tiene que ingresar la conversion')
          }
        },
      }).then(result => {
        if (result.value) {
          console.log(result)
        }
      })
    }
    const getParametros = async () => {
      if (userData.value.authorities[0].authority === 'VENDEDOR GRANEL') {
        isCalcular.value = false
      }
      console.info('isCalcular', isCalcular.value, userData.value.authorities[0].authority)
      Vue.swal({
        title: 'Obteniendo Parametros',
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: async () => {
          Vue.swal.showLoading()
          await store.dispatch('comercial/VENTAS_PARAMETERS', {
          })
            .then(response => {
              console.log(response)
              if (response) {
                presentaciones.value = response
                selectFormaPago.value = response.forma_pago
                selectTipoDocumento.value = []
                response.tipo_documento.forEach(row => {
                  if (row.venta) {
                    selectTipoDocumento.value.push(row)
                    if (row.tipoDocumentoId === 1) {
                      formData.value.tipoDocumento = row
                    }
                  }
                })
                selectTipoVenta.value = response.tipo_venta
                selectClienteFinal.value = response.cliente
                getSeries(formData.value.tipoDocumento)
              }
            })
          await store.dispatch('facturacion/GET_PRODUCTO_SEARCH_FACTURACION', { limit: -1, page: -1, query: '', sortBy: '' })
            .then(response => {
              response.items.forEach(value => {
                const row = value
                row.productoNombre = `${value.presentacion.descripcion} - ${value.presentacion.marca.descripcion}`
                selectProducto.value.push(row)
              })
            })
          if (userData.value.authorities[0].authority === 'VENDEDOR CILINDROS' || userData.value.authorities[0].authority === 'VENDEDOR GRANEL') {
            selectVendedor.value.push({ id: userData.value.id, nombreCompleto: userData.value.nombreCompleto })
            formData.value.vendedor = { id: userData.value.id, nombreCompleto: userData.value.nombreCompleto }
          }
          if (userData.value.authorities[0].authority === 'GERENTE GENERAL') {
            selectVendedor.value = []
            await store.dispatch('usuario/USUARIO_ALL_SALES', { tipo_usuario: '-' })
              .then(response => {
                response.items.forEach(value => {
                  const row = value
                  selectVendedor.value.push(row)
                })
              })
          }
          // document.querySelector("#documentoCliente").focus()
          Vue.swal.close()
        },
      })
    }
    const formParse = () => {
      const form = {
        sunatVenta: {},
        detalleVentas: [],
      }
      const sunatVenta = { almacen: { almacenId: formData.value.vendedor.id },
        clienteDato: `${formData.value.razon_social}|${formData.value.direccion}|${formData.value.documentoCliente}`,
        clienteId: formData.value.cliente != null ? formData.value.cliente.idCliente : 0,
        email: formData.value.email,
        envio: true,
        estado: true,
        fechaVenta: '',
        fechaVencimiento: formData.value.fechaVencimiento,
        formatoImpresion: formData.value.formaPrint.descripcion,
        formatoPago: formData.value.formaPago,
        igv: 0,
        latitud: 'oficina',
        longitud: 'oficina',
        montoIgv: formData.value.igv,
        numeroComprobante: formData.value.nro_comprobante,
        observacion: formData.value.observacion,
        sub_total: formData.value.subTotal,
        tipoDocumento: formData.value.tipoDocumento,
        tipoMoneda: { tipoMonedaId: 1 },
        tipoVenta: formData.value.tipoVenta,
        total: formData.value.total,
        vendedorId: 0,
        ventaId: 0,
        tipoPago: formData.value.tipoPago.descripcion,
        cantidadFise: 0,
        totalFise: formData.value.tipoPago.descripcion === 'BONO_FISE' ? formData.value.cantidadFise : 0,
        clienteFinal: formData.value.clienteFinal,
      }
      form.sunatVenta = sunatVenta
      items.value.forEach(element => {
        console.info('DETALLE ROW', element)
        const row = {
          cantidad: element.cantidad,
          detalleVentaId: 0,
          estado: true,
          igv: 0,
          observacion: '',
          precio: element.precio,
          productoDato: element.productoNombre,
          productoId: element.idProducto,
          subTotal: element.subtotal,
          sunatVenta: { ventaId: 0 },
          tipo: 'VENDIDO',
          unidadMedidaDato: element.unidadMedida.abreviatura,
          unidadMedidaId: 0,
        }
        form.detalleVentas.push(row)
      })
      // console.log(JSON.stringify(form))
      return (form)
    }
    const onSubmit = async () => {
      try {
        if (formData.value.tipoPago.descripcion === 'BONO_FISE') {
          if (formData.value.cantidadFise === null || formData.value.cantidadFise === 0 || formData.value.cantidadFise === '') {
            throw new Error('TIENE QUE INGRESAR EL MONTO FISE')
          }

          if (Number(formData.value.cantidadFise) > Number(formData.value.saldoFise)) {
            throw new Error('SU IMPORTE FISE EXCESE EL SALDO DISPONIBLE')
          }
        }

        Vue.swal({
          title: 'Registrando la venta',
          showCancelButton: false,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          onOpen: async () => {
            Vue.swal.showLoading()
            const service = 'comercial/VENTA_CREATE'
            // formData.value.presentacion = presentacionSel.value
            await store.dispatch(service, formParse())
              .then(response => {
                Vue.swal.close()
                notify('Respuesta', response.message, 'success')
                items.value = []
                resetData()
                getParametros()
              })
              .catch(error => {
                Vue.swal.close()
                console.log(error)
                throw new Error(error)
              })
          },
        })
      } catch (error) {
        console.log(error)
        Vue.swal.close()
        notify('Error', error.message, 'danger')
      }
    }

    const calcularVuelto = () => {
      const vuelto = formData.value.total - formData.value.efectivo
      formData.value.vuelto = 0
      if (vuelto <= 0) {
        formData.value.vuelto = vuelto * -1
      }
    }
    const getsaldoCliente = async idCliente => {
      await store
        .dispatch('comercial/CONTROL_FISE_SALDO_ALL', {
          tipo: 'grilla',
          limit: 10,
          query: '',
          page: '-1',
          sortBy: '',
          cliente_id: idCliente,
        })
        .then(response => {
          // this.items = response.items.map(venta => ({ ...venta, clienteRuc: venta.venta.clienteDato.split('|')[2], clienteNombre: venta.venta.clienteDato.split('|')[0] }))
          if (response.items.length > 0) {
            formData.value.saldoFise = parseFloat(response.items[0].saldo - response.items[0].consumido).toFixed(2)
            console.log(response.items)
          }
        })
        .catch(error => {
          this.showToast('Ha ocurrido un error', error, 'danger')
        })
    }
    const getSearchDocument = async tDocumento => {
      // this.isBusy = true
      await store
        .dispatch('comercial/CLIENTE_SEARCH_DOCUMENTO', {
          documento: formData.value.documentoCliente,
          tipo_documento: tDocumento,
        })
        .then(async response => {
          console.log(response)
          formData.value.cliente = response.data
          formData.value.razon_social = response.data.razonSocial
          formData.value.direccion = response.data.direccion
          if (formData.value.tipoPago.descripcion === 'BONO_FISE') {
            await getsaldoCliente(formData.value.cliente.idCliente)
          }
        })
        .catch(error => {
          console.log(error)
          notify('Error', error.message, 'danger')
        })
      // this.isBusy = false
    }
    const changeProducto = () => {
      // console.log(formData.value.preProducto)
      formData.value.prePrecio = (formData.value.preProducto.precioDefault).toFixed(3)
      selectunidadMedida.value = []
      // 1 es unidad y 2 es galon,kg
      if (formData.value.preProducto.presentacion.tipoProducto === 1) {
        selectunidadMedida.value = [{ descripcion: 'UNIDAD', abreviatura: 'NIU', convercion: 1 }]
        formData.value.preUnidadMedida = { descripcion: 'UNIDAD', abreviatura: 'NIU', convercion: 1 }
      }
      if (formData.value.preProducto.presentacion.tipoProducto === 2) {
        selectunidadMedida.value = [{ descripcion: 'GALONES', abreviatura: 'GLL', convercion: 10.018 }, { descripcion: 'LITRO', abreviatura: 'LTR', convercion: 2.018 }, { descripcion: 'KILOGRAMOS', abreviatura: 'KGM', convercion: 3.78541 }]
        formData.value.preUnidadMedida = { descripcion: 'GALONES', abreviatura: 'GLL', convercion: 2.018 }
        if (formData.value.preProducto.presentacion.idPresentacion === 14) {
          formData.value.preUnidadMedida = { descripcion: 'KILOGRAMOS', abreviatura: 'KGM', convercion: 2.018 }
        }
        if (formData.value.preProducto.presentacion.idPresentacion === 15) {
          formData.value.preUnidadMedida = { descripcion: 'LITRO', abreviatura: 'LTR', convercion: 2.018 }
        }
      }
    }
    const changeunidadMedida = () => {
      console.log('unidad')
    }
    const cleanPreData = () => {
      formData.value.preCantidad = null
      formData.value.prePrecio = null
      formData.value.preProducto = {}
    }
    const searchKey = row => {
      let seacrh = ''
      items.value.forEach((value, index) => {
        if (row.idProducto === value.idProducto) {
          seacrh = index
        }
      })
      return seacrh
    }
    const calcularSubtotal = () => {
      let subtotal = 0
      items.value.forEach(value => {
        subtotal += parseFloat(value.subtotal)
      })
      formData.value.total = parseFloat(subtotal)
      formData.value.subTotal = parseFloat(subtotal)
      calcularVuelto()
    }
    const addDetalle = () => {
      try {
        // const valPro = formData.value.preProducto === null
        // console.log({ valPro })
        // console.info(formData.value.preProducto, formData.value.preCantidad, formData.value.prePrecio)
        // VALIDAR CANTIDAD
        if (formData.value.preProducto === '' || formData.value.preProducto === 0 || formData.value.preProducto === undefined || formData.value.preProducto === null) {
          // formData.value.preCantidad.focus()
          throw new Error('TIENE QUE SELECCIONAR UN PRODUCTO')
        }
        if (formData.value.preCantidad === '' || formData.value.preCantidad === 0 || formData.value.preCantidad === undefined || formData.value.preCantidad === null) {
          // formData.value.preCantidad.focus()
          throw new Error('TIENE QUE INGRESAR LA CANTIDAD')
        }
        // VALIDAR PRECIO
        if (formData.value.prePrecio === '' || formData.value.prePrecio === 0 || formData.value.prePrecio === undefined || formData.value.prePrecio === null) {
          formData.value.prePrecio.focus()
          throw new Error('TIENE QUE INGRESAR EL PRECIO')
        }
        // VALIDAR SI EXISTE EL PRODUCTO
        const row = {
          ...formData.value.preProducto,
          precio: formData.value.prePrecio,
          cantidad: formData.value.preCantidad,
          subtotal: parseFloat(formData.value.prePrecio * formData.value.preCantidad).toFixed(2),
          key: (items.value.length) === 0 ? 0 : (items.value.length + 1),
          unidadMedida: formData.value.preUnidadMedida,
          isService: false }
        if (searchKey(row) !== '') {
          throw new Error('EL PRODUCTO YA FUE AGREDADO')
        }
        console.log({ row })
        items.value.push(row)
        formData.value.preUnidadMedida = []
        formData.value.preCantidad = null
        cleanPreData()
        formData.value.preProducto = null
        calcularSubtotal()
        notify('', 'Se agrego el detalle', 'success')
      } catch (error) {
        console.log(error)
        notify('Error', error.message, 'danger')
      }
    }
    const addDetalleLibre = () => {
      const row = {
        productoNombre: '',
        precio: parseFloat(0).toFixed(2),
        cantidad: parseFloat(0).toFixed(2),
        subtotal: parseFloat(0).toFixed(2),
        key: (items.value.length) === 0 ? 0 : (items.value.length + 1),
        unidadMedida: { descripcion: 'SERVICIO', abreviatura: 'ZZ', convercion: 1 },
        isService: true }
      if (searchKey(row) !== '') {
        throw new Error('EL PRODUCTO YA FUE AGREDADO')
      }
      console.log({ row })
      items.value.push(row)
    }
    const deleteDetalle = row => {
      items.value.forEach((value, index) => {
        if (row.idProducto === value.idProducto) {
          items.value.splice(index, 1)
        }
      })
      calcularSubtotal()
    }
    const changeCantidadPrecio = (row, tipo) => {
      console.info(row, tipo, items)
      items.value.forEach((element, index) => {
        if (element.key === row.key) {
          items.value[index].subtotal = parseFloat(row.cantidad * row.precio).toFixed(2)
        }
      })
      calcularSubtotal()
      // row.subtotal = parseFloat(row.cantidad * row.precio).toFixed(2)
    }
    const getFechaVencimiento = () => {
      isVencimiento.value = false
      if (formData.value.tipoVenta.tipoVentaId === 1) {
        isVencimiento.value = true
      }
    }
    const changeTipoPago = async () => {
      console.log(formData.value.tipoPago)
      console.log(formData.value.cliente)
      isFise.value = true
      if (formData.value.tipoPago.descripcion === 'BONO_FISE') {
        isFise.value = false
        if (formData.value.cliente != null && formData.value.cliente !== 0) {
          await getsaldoCliente(formData.value.cliente.idCliente)
        }
      } else {
        formData.value.cantidadFise = 0
      }
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetData)
    getParametros()
    return {
      formData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      titleForm,
      getParametros,
      presentaciones,
      presentacionSel,
      selectVendedor,
      selectTipoVenta,
      selectFormaPago,
      selectTipoDocumento,
      selectSerie,
      getSeries,
      getNroComprobante,
      selectFormaPrint,
      getSearchDocument,
      selectProducto,
      selectunidadMedida,
      fieldsDetalle,
      changeProducto,
      items,
      sortBy,
      isBusy,
      addDetalle,
      addDetalleLibre,
      cleanPreData,
      calcularSubtotal,
      deleteDetalle,
      changeCantidadPrecio,
      searchKey,
      formParse,
      changeunidadMedida,
      userProfile,
      userData,
      calcularVuelto,
      isVencimiento,
      getFechaVencimiento,
      calcularCantidadGranel,
      isCalcular,
      calculoGranel,
      selectTipoPago,
      changeTipoPago,
      isFise,
      selectClienteFinal,
      getsaldoCliente,
    }
  },
}
</script>

<style>

</style>
